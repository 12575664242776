import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import { range } from "lodash";
import { yearOptions } from "../../helpers/selectOptions";
import { isBefore } from "date-fns";

function getYearInstalledOptions(yearOfRoof: number): any {
  return range(yearOfRoof, new Date().getFullYear() + 1).map(
    (item: number) => ({
      value: item,
      label: item.toString()
    })
  );
}

function atlasTDISchema(yearOfRoof: number, atlasCompanyNumbers: number[]) {
  return [
    ...useFormSection(
      [
        {
          element: "html",

          tagName: "p",
          innerText: __getText("global", "creditsDisclaimer"),
          classList: ["w-full", "text-red-600", "mb-10", "-mt-10"]
        },
        ...useFormFieldGroup(
          [
            useSelectField({
              key: "yearInstalledAtlas",
              label: `Year Installed (${atlasCompanyNumbers.join(", ")})`,
              filterable: true,
              clearable: true,
              options: getYearInstalledOptions(yearOfRoof),
              required: true,
              conditions: {
                and: [
                  {
                    field: "hailResistantRoofAtlas",
                    value: true,
                    operator: "exists"
                  },
                  {
                    field: "hailResistantRoofAtlas",
                    value: "0",
                    operator: "not"
                  },
                  {
                    field: "hasRoofYear",
                    value: true,
                    operator: "equals"
                  }
                ]
              }
            })
          ],
          { layout: "2-col" }
        )
      ],
      {
        title: "Atlas TDI Credits",
        description: ""
      }
    )
  ];
}

// Schema for Atlas When Homeowner
export function discountsFormAtlasHO(
  atlasDiscountParams: any,
  accreditedBuilderName: any
): FormBlock[] {
  const {
    yearOfRoof,
    atlasCompanyNumbers,
    atlasCompanyNumbersWithout27
  } = atlasDiscountParams;
  return [
    ...useFormSection(
      [
        {
          element: "html",

          tagName: "p",
          innerText: __getText("global", "creditsDisclaimer"),
          classList: ["w-full", "text-red-600", "mb-10", "-mt-10"]
        },
        ...useFormFieldGroup(
          [
            useField(
              {
                key: "accreditedBuilder",
                label: `Accredited Builder - Applies To Companies: ${atlasCompanyNumbers.join(
                  ", "
                )} (10%-15%) `,
                type: "checkbox",
                required: false
              },
              ["-mb-20"]
            ),
            useSelectField(
              {
                key: "accreditedBuilderName",
                label: `Name`,
                options: accreditedBuilderName,
                filterable: true,
                allowFirstOption: true,
                required: true,
                conditions: {
                  and: [
                    {
                      field: "accreditedBuilder",
                      value: true,
                      operator: "equals"
                    }
                  ]
                }
              },
              ["-mb-20 -mt-2"]
            )
          ],
          { layout: "2-col" }
        ),
        ...useFormFieldGroup(
          [
            useField(
              {
                key: "companionAutoAtlas",
                label: `Companion Auto  - Applies To Companies: ${atlasCompanyNumbersWithout27.join(
                  ", "
                )} (5%)`,
                type: "checkbox",
                required: false
              },
              ["my-5"]
            ),
            useField(
              {
                key: "monitoredAlarmAtlas",
                label: `Monitored Alarm - Applies To Companies: ${atlasCompanyNumbersWithout27.join(
                  ", "
                )} (5%)`,
                type: "checkbox",
                required: false
              },
              ["my-5"]
            ),
            useField(
              {
                key: "lossFreeExperienceCreditAtlas",
                label: `Loss Free Experience Credit (5%) – Must be loss free with Atlas for two years (${atlasCompanyNumbers.join(
                  ", "
                )})`,
                type: "checkbox",
                required: false
              },
              ["my-5"]
            )
          ],
          { layout: "full" }
        ),
        ...fortifiedRoof("fortifiedRoof", atlasCompanyNumbers)
      ],
      {
        title: "Atlas Credits",
        description: ""
      }
    ),
    ...atlasTDISchema(yearOfRoof, atlasCompanyNumbers)
  ];
}

export function fortifiedRoof(
  key: "fortifiedRoofColonial" | "fortifiedRoof",
  atlasCompanyNumbers?: number[]
): any {
  if (
    key === "fortifiedRoof" &&
    atlasCompanyNumbers &&
    !atlasCompanyNumbers.includes(26)
  ) {
    return [];
  }
  const isAtlas = key === "fortifiedRoof";
  const percentageString = isAtlas ? "26 (16%)" : "20 (up to 16%)";

  return [
    ...useFormFieldGroup(
      [
        useField({
          key,
          label: `Fortified Roof Credit - Applies To Companies: ${percentageString}`,
          type: "checkbox",
          required: false
        }),
        useField({
          key: `${
            isAtlas
              ? "certificateExpirationDate"
              : "colonialCertificateExpirationDate"
          }`,
          label: `Certificate Expiration Date`,
          type: "date",
          required: true,
          pickerOptions: {
            disabledDate: (d: Date) => isBefore(d, new Date())
          },
          conditions: {
            and: [
              {
                field: key,
                value: true,
                operator: "equals"
              }
            ]
          }
        })
      ],
      { layout: "2-col" }
    )
  ];
}

// Schema for Atlas When Dwelling Fire
export function discountsFormAtlasDF(atlasDiscountParams: any): FormBlock[] {
  const { yearOfRoof, atlasCompanyNumbers } = atlasDiscountParams;
  return [...atlasTDISchema(yearOfRoof, atlasCompanyNumbers)];
}

// Schema for Colonial whether Homeowner or not
export function colonialTDICredits(): FormBlock[] {
  return [
    ...useFormSection(
      [
        {
          element: "html",

          tagName: "p",
          innerText: __getText("global", "creditsDisclaimer"),
          classList: ["w-full", "text-red-600", "mb-10", "-mt-10"]
        },
        ...useFormFieldGroup(
          [
            useSelectField({
              key: "yearOfRoof",
              label: "Year of Roof (20 Statewide & Seacoast)",
              options: yearOptions,
              disabled: true,
              filterable: true
            })
          ],
          { layout: "2-col" }
        )
      ],
      {
        title: "Roadrunner TDI Credits",
        description: ""
      }
    )
  ];
}

// schema for Colonial Dwelling Fire
export function discountsFormColonialDF(): FormBlock[] {
  return [...colonialTDICredits()];
}

export function discountsFormColonialHO(
  yearBuilt: any,
  effectiveDate: Date
): FormBlock[] {
  return [
    ...useFormSection(
      [
        {
          element: "html",

          tagName: "p",
          innerText: __getText("global", "creditsDisclaimer"),
          classList: ["w-full", "text-red-600", "mb-10", "-mt-10"]
        },
        ...discountsFormColonialStateWide(),
        ...discountsFormColonialStateWideSeaTerritory(effectiveDate),
        ...discountFormColonialSeaCoastSeaTerritory(yearBuilt),
        ...useFormFieldGroup(
          [
            useField(
              {
                key: "lossFreeExperienceCredit",
                label: `Loss Free Experience Credit (5%) – Must be loss free with Atlas for two years (20 Statewide & Seacoast)`,
                type: "checkbox",
                required: false
              },
              ["my-5"]
            )
          ],
          { layout: "full" }
        ),
        ...fortifiedRoof("fortifiedRoofColonial"),
        ...useFormFieldGroup(
          [
            useTextField({
              key: "ageOfHome",
              label:
                "Age of risk – Automatically applied based on year built (20 Statewide & Seacoast)",
              required: false,
              disabled: true,
              validations: [
                {
                  method: "integer",
                  error: __getText("fields", "numberError")
                }
              ]
            })
          ],
          { layout: "2-col" }
        )
      ],
      {
        title: "Roadrunner Credits",
        description: ""
      }
    ),
    ...colonialTDICredits()
  ];
}

// Schema for Colonial When Homeowner and sea coast and has no sea territory
export function discountsFormColonialSeaCoast(): FormBlock[] {
  return [
    // ...useFormFieldGroup(
    //   [
    //     useField({
    //       key: "hasAutomaticSprinkler",
    //       label: "Automatic Sprinkler System (20 Seacoast)",
    //       type: "checkbox",
    //       required: false
    //     })
    //   ],
    //   { layout: "full" }
    // ),
    // ...useFormFieldGroup(
    //   [
    //     useField({
    //       key: "hasNonCombustibleRoof",
    //       label: "Non-Combustible Roof (20 Seacoast)",
    //       type: "checkbox",
    //       required: false
    //     })
    //   ],
    //   { layout: "full" }
    // ),
    // ...useFormFieldGroup(
    //   [
    //     useField({
    //       key: "hasFireExtinguisher",
    //       label: "Fire Extinguisher (20 Seacoast)",
    //       type: "checkbox",
    //       required: false
    //     })
    //   ],
    //   { layout: "full" }
    // ),
    // ...useFormFieldGroup(
    //   [
    //     useSelectField({
    //       key: "monitoredAlarmSC",
    //       label: "Monitored Alarm (20 Seacoast)",
    //       options: monitoringAlarmOptions,
    //       required: false,
    //       multiple: false
    //     }),
    //     useTextField({
    //       key: "ageOfInsuredSC",
    //       label: "Age of Insured (20 Seacoast)",
    //       required: false,
    //       validations: [
    //         {
    //           method: "integer",
    //           error: __getText("fields", "numberError")
    //         },
    //         {
    //           method: "custom",
    //           error: "Age of insured must be 60 years or older",
    //           customValidator: (value: any) => {
    //             if (value && value < 60) {
    //               return false;
    //             } else {
    //               return true;
    //             }
    //           }
    //         }
    //       ]
    //     })
    //   ],
    //   { layout: "2-col" }
    // )
  ];
}

// Schema for colonial when homeowner and sea coast and has sea territory
export function discountFormColonialSeaCoastSeaTerritory(
  yearBuilt: any
): FormBlock[] {
  return [
    ...useFormFieldGroup(
      [
        useField({
          key: "newHomeCredit",
          label: "New Home Credit (20 Seacoast)",
          type: "checkbox",
          required: false,
          validations: [
            {
              method: "custom",
              error: "Year built is required. Please enter the year built",
              customValidator: (value: any) => {
                if (value && !yearBuilt) {
                  return false;
                } else {
                  return true;
                }
              }
            },
            {
              method: "custom",
              error:
                "Year Built must not be older than 5 years for discount to apply",
              customValidator: (value: any) => {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                if (value && currentYear - yearBuilt > 5) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          ]
        })
      ],
      { layout: "full" }
    )
  ];
}

// Schema for Colonial When Homeowner and state wide and has no sea terr
export function discountsFormColonialStateWide(): FormBlock[] {
  return [
    ...useFormFieldGroup(
      [
        useField({
          key: "monitoredAlarmSW",
          label: "Monitored Alarm - (5%) (20 Statewide)",
          type: "checkbox",
          required: false
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useField({
          key: "ageOfInsuredCheck",
          type: "checkbox",
          label: "Mature Citizen- (55 or older) (20 Statewide)",
          required: false
        }),
        useTextField({
          key: "ageOfInsuredSW",
          label: "Age of Insured (20 Statewide)",
          placeholder: "Age of Insured",
          required: true,
          conditions: {
            and: [
              {
                field: "ageOfInsuredCheck",
                value: true,
                operator: "equals"
              }
            ]
          },
          validations: [
            {
              method: "custom",
              error: "Age of Insured must be 55 yrs or older",
              customValidator: (value: any) => {
                if (value && value < 55) {
                  return false;
                } else {
                  return true;
                }
              }
            },
            {
              method: "integer",
              error: __getText("fields", "numberError")
            }
          ]
        })
      ],
      { layout: "2-col" }
    )
  ];
}

// Schema for Colonial When Homeowner and state wide and has sea terr
export function discountsFormColonialStateWideSeaTerritory(
  effectiveDate: Date
): FormBlock[] {
  return [
    ...useFormFieldGroup(
      [
        useField({
          key: "companionAuto",
          label: "Companion Auto (5%) (20 Statewide)",
          type: "checkbox",
          required: false
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useField({
          key: "hvacAge",
          label: "HVAC Age - (5 years or newer) (20 Statewide)",
          type: "checkbox",
          required: false
        }),
        useSelectField({
          key: "hvacInstallationYear",
          label: "HVAC Installation Year (20 Statewide)",
          filterable: true,
          options: range(
            new Date(effectiveDate).getFullYear() - 4,
            new Date(effectiveDate).getFullYear() + 1
          ).map((item: number) => ({
            value: item,
            label: item.toString()
          })),
          required: true,
          conditions: {
            and: [
              {
                field: "hvacAge",
                value: true,
                operator: "equals"
              }
            ]
          }
        })
      ],
      { layout: "2-col" }
    )
  ];
}
