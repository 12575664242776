
import Vue from "vue";

import { quoteMapState } from "@/store/modules/quote";

export default Vue.extend({
  inheritAttrs: false,
  name: "DisplayHO215Data",
  computed: {
    ...quoteMapState(["editing"]),
    editingEndorsement(): any {
      if (this.editing && this.editing.endorsements) {
        return this.editing.endorsements.find(
          (endorsement: any) => endorsement.formCode === "215"
        );
      }
      return [];
    },
    numberOfWaterCrafts(): any {
      if (
        this.editingEndorsement &&
        this.editingEndorsement.values &&
        this.editingEndorsement.values.length > 0
      ) {
        const match = this.editingEndorsement.values.find(
          (value: any) => value.key == "m_fmNumWaterCraft"
        );
        return match ? match.value : "";
      }
      return {};
    },
    waterCraftData(): any {
      if (
        this.editingEndorsement &&
        this.editingEndorsement.values &&
        this.editingEndorsement.values.length > 0
      ) {
        const match = this.editingEndorsement.values.find(
          (value: any) => value.key == "waterCraftData"
        );
        return match ? match.value : {};
      }
      return {};
    }
  }
});
