
import Vue from "vue";

export interface IData {
  error: string;
  loading: boolean;
  output: any;
  optionsExample: any[];
  expanded: boolean;
}

export default Vue.extend({
  name: "endorsementsEditor",
  props: {
    endorsements: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedCompanies: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data(): any {
    return {
      expanded: false,
      error: "",
      loading: false,
      output: {}
    };
  },
  methods: {
    getCompanies(endorsement: any) {
      let displayCompanies = endorsement.companies.filter((company: number) => {
        return this.selectedCompanies.includes(company);
      });
      return displayCompanies
        .toString()
        .replaceAll(",", ", ")
        .replaceAll("20", "20 Seacoast")
        .replaceAll("99", "20 Statewide");
    }
  },
  computed: {
    faIcon(): string {
      return this.expanded ? "chevron-up" : "chevron-right";
    }
  }
});
