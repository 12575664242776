<template>
  <ModalBase
    :name="$attrs.name"
    :size="size"
    :clickToClose="true"
    :showClose="true"
    title="Building Amount"
    :description="
      `Building amount differs depending on the company. You entered ${buildingAmount}, but some companies have different requirements. Make adjustments below.`
    "
    @before-open="$emit('before-open', $event)"
    @before-close="$emit('before-close', $event)"
    @opened="$emit('opened', $event)"
    @closed="$emit('closed', $event)"
  >
    <div>
      <div
        v-for="(company, index) in buildingAmountDisplayOptions"
        :key="`company-building-amount-${index}`"
        class="flex justify-between items-center border-b last:border-none mb-4"
      >
        <div class="pr-0 sm:pr-6">
          <div class="text-lg flex items-center">
            <span>{{ company.companyName }}</span>
            <span class="ml-1">- {{ company.companyNumber }}</span>
            <span class="ml-2 text-sm text-gray-500 font-medium">{{
              company.formTypes.join(", ")
            }}</span>
          </div>
          <div class="text-gray-800">
            When rating with the form
            {{ company.formTypes.length > 1 ? "types" : "type" }}
            {{ company.formTypes.join(", ") }}, the minimum value is
            {{ company.minimumDwellingAmount }}, the maximum is
            {{ company.maximumBuildingAmount }}.
            <span
              class="text-red-500"
              v-if="buildingAmount < company.minimumDwellingAmount"
              >The value has been adjusted to meet the minimum.</span
            >
            <span
              class="text-red-500"
              v-if="buildingAmount > company.maximumBuildingAmount"
              >The value has been adjusted to stay within the maximum.</span
            >
          </div>
        </div>
        <TextField
          class="w-64 mt-14"
          :value="
            buildingAmount > company.maximumBuildingAmount
              ? company.maximumBuildingAmount
              : buildingAmount < company.minimumDwellingAmount
              ? company.minimumDwellingAmount
              : buildingAmount
          "
          :placeholder="''"
          :required="true"
          type="text"
          :clearable="true"
          @input="emitValues(company, $event)"
          :validations="[
            { method: 'integer', error: 'Please enter a number' },
            {
              method: 'minValue',
              value: company.minimumDwellingAmount,
              error: `Must not be less than ${company.minimumDwellingAmount}`
            },
            {
              method: 'maxValue',
              value: company.maximumBuildingAmount,
              error: `Must not be more than ${company.maximumBuildingAmount}`
            }
          ]"
        />
      </div>
    </div>

    <div class="mt-5 sm:mt-6 flex justify-center" v-if="buttons.length">
      <IAButton
        v-for="(item, index) in buttons"
        :key="`modal_${$attrs.name}_button_${index}`"
        :title="item.text"
        :click="item.click"
        :class="item.classList"
        size="medium"
      />
    </div>
  </ModalBase>
</template>
<script>
import ModalBase from "./ModalBase.vue";
import { get } from "lodash";
export default {
  name: "ModalBuildingAmount",
  components: { ModalBase },
  /*
    Not listed as a prop, but this component _requires_ a name attribute.
    */
  props: {
    /* Used to pass through to ModalBase */
    clickToClose: {
      type: Boolean,
      default: false
    },
    /* Used to pass through to ModalBase */
    showClose: {
      type: Boolean,
      default: false
    },
    /* All below are unique to this modal */
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    icon: {
      type: Array,
      default: () => []
    },
    iconClass: {
      type: String,
      default: ""
    },
    buttons: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: "medium"
    },

    overrideOptions: {
      type: Array,
      default: () => []
    },
    companiesData: {
      type: Array,
      default: () => []
    },
    formValues: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      values: []
    };
  },
  methods: {
    emitValues({ formTypes, companyNumber }, $event) {
      for (const formType of formTypes) {
        this.$emit("updateAmountDWPCompanyOverrides", {
          formType: formType,
          companyNumber: companyNumber,
          value: $event
        });
      }
    }
  },
  computed: {
    buildingAmount() {
      const { formValues } = this;
      return get(formValues, "amountDWP", "");
    },
    buildingAmountDisplayOptions() {
      const uniqueOptions = [];
      const { overrideOptions } = this;

      const hasSameBuildingAmountRequirements = (option1, option2) =>
        option1.maximumBuildingAmount === option2.maximumBuildingAmount &&
        option1.minimumDwellingAmount === option2.minimumDwellingAmount;

      const isSameCompany = (option1, option2) =>
        option1.companyNumber === option2.companyNumber;

      for (const companyData of overrideOptions) {
        const existingCompanyWithSameBuildingAmountRequirements = uniqueOptions.findIndex(
          (
            a // eslint-disable-line prettier/prettier
          ) =>
            isSameCompany(a, companyData) &&
            hasSameBuildingAmountRequirements(a, companyData)
        );

        if (existingCompanyWithSameBuildingAmountRequirements === -1) {
          uniqueOptions.push({
            companyName: companyData.companyName,
            companyNumber: companyData.companyNumber,
            formTypes: [companyData.formType],
            minimumDwellingAmount: companyData.minimumDwellingAmount,
            maximumBuildingAmount: companyData.maximumBuildingAmount
          });
        } else {
          uniqueOptions[
            existingCompanyWithSameBuildingAmountRequirements
          ].formTypes.push(companyData.formType);
        }
      }
      return uniqueOptions;
    }
  }
};
</script>
