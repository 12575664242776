
import Vue from "vue";
import { checkVuelidateValidation } from "@/helpers/processValidation";
import { quoteMapState } from "@/store/modules/quote";
import { set, get } from "lodash";

export default Vue.extend({
  inheritAttrs: false,
  name: "HO215Fields",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [Boolean, String, Number],
      required: false,
      default: false
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      HO225Fami1: "",
      HO225Fami2: "",
      HO225Fami3: "",
      HO225Fami4: "",
      HO225Fami5: "",
      HO225Fami6: "",
      HO225Loca1: "",
      HO225Loca2: "",
      HO225Loca3: "",
      HO225Loca4: "",
      HO225Loca5: "",
      HO225Loca6: "",
      oneTwoOptions: [
        { value: "1", label: "1" },
        { value: "2", label: "2" }
      ]
    };
  },
  mounted() {
    const valuesArray = [
      "HO225Fami1",
      "HO225Fami2",
      "HO225Fami3",
      "HO225Fami4",
      "HO225Fami5",
      "HO225Fami6",
      "HO225Loca1",
      "HO225Loca2",
      "HO225Loca3",
      "HO225Loca4",
      "HO225Loca5",
      "HO225Loca6"
    ];
    const endorsement = this.editing.endorsements.find((e: any) => {
      return e.formCode === "225";
    });
    if (endorsement && endorsement.values) {
      for (let value of valuesArray) {
        const endorsementValue = endorsement.values.find((val: any) => {
          return val.key == value;
        });
        if (endorsementValue) {
          set(this, value, endorsementValue.value);
        }
      }
    }
  },
  methods: {
    inputValueChanged($event: any, ref: string): void {
      const value = {
        value: $event,
        ref: ref
      };
      this.$emit("change", value);
    },
    getNumberOfFamilies(num: number): any {
      return get(this, `HO225Fami${num}`);
    },
    getLocation(num: number): any {
      return get(this, `HO225Loca${num}`);
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    validationResult(): {
      isValid: boolean;
      error: string;
    } {
      return checkVuelidateValidation(this.validations as any, this.value);
    }
  }
});
