var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalBase',{attrs:{"name":_vm.$attrs.name,"size":_vm.size,"clickToClose":true,"showClose":true,"title":"Building Amount","description":`Building amount differs depending on the company. You entered ${_vm.buildingAmount}, but some companies have different requirements. Make adjustments below.`},on:{"before-open":function($event){return _vm.$emit('before-open', $event)},"before-close":function($event){return _vm.$emit('before-close', $event)},"opened":function($event){return _vm.$emit('opened', $event)},"closed":function($event){return _vm.$emit('closed', $event)}}},[_c('div',_vm._l((_vm.buildingAmountDisplayOptions),function(company,index){return _c('div',{key:`company-building-amount-${index}`,staticClass:"flex justify-between items-center border-b last:border-none mb-4"},[_c('div',{staticClass:"pr-0 sm:pr-6"},[_c('div',{staticClass:"text-lg flex items-center"},[_c('span',[_vm._v(_vm._s(company.companyName))]),_c('span',{staticClass:"ml-1"},[_vm._v("- "+_vm._s(company.companyNumber))]),_c('span',{staticClass:"ml-2 text-sm text-gray-500 font-medium"},[_vm._v(_vm._s(company.formTypes.join(", ")))])]),_c('div',{staticClass:"text-gray-800"},[_vm._v(" When rating with the form "+_vm._s(company.formTypes.length > 1 ? "types" : "type")+" "+_vm._s(company.formTypes.join(", "))+", the minimum value is "+_vm._s(company.minimumDwellingAmount)+", the maximum is "+_vm._s(company.maximumBuildingAmount)+". "),(_vm.buildingAmount < company.minimumDwellingAmount)?_c('span',{staticClass:"text-red-500"},[_vm._v("The value has been adjusted to meet the minimum.")]):_vm._e(),(_vm.buildingAmount > company.maximumBuildingAmount)?_c('span',{staticClass:"text-red-500"},[_vm._v("The value has been adjusted to stay within the maximum.")]):_vm._e()])]),_c('TextField',{staticClass:"w-64 mt-14",attrs:{"value":_vm.buildingAmount > company.maximumBuildingAmount
            ? company.maximumBuildingAmount
            : _vm.buildingAmount < company.minimumDwellingAmount
            ? company.minimumDwellingAmount
            : _vm.buildingAmount,"placeholder":'',"required":true,"type":"text","clearable":true,"validations":[
          { method: 'integer', error: 'Please enter a number' },
          {
            method: 'minValue',
            value: company.minimumDwellingAmount,
            error: `Must not be less than ${company.minimumDwellingAmount}`
          },
          {
            method: 'maxValue',
            value: company.maximumBuildingAmount,
            error: `Must not be more than ${company.maximumBuildingAmount}`
          }
        ]},on:{"input":function($event){return _vm.emitValues(company, $event)}}})],1)}),0),(_vm.buttons.length)?_c('div',{staticClass:"mt-5 sm:mt-6 flex justify-center"},_vm._l((_vm.buttons),function(item,index){return _c('IAButton',{key:`modal_${_vm.$attrs.name}_button_${index}`,class:item.classList,attrs:{"title":item.text,"click":item.click,"size":"medium"}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }