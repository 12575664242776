
import Vue from "vue";
import { checkVuelidateValidation } from "@/helpers/processValidation";
import { quoteMapState } from "@/store/modules/quote";
import { set } from "lodash";
import * as selectOptions from "@/helpers/selectOptions";

type HO215Keys =
  | "HO215Des1"
  | "HO215HP"
  | "HO215Des2"
  | "HO215MPH"
  | "HO215Len2"
  | "HO215Des3"
  | "HO215Ax"
  | "HO215Len3"
  | "m_fmNumWaterCraft"
  | "waterCraftData";
interface IData {
  HO215Data: { [key in HO215Keys]: Record<string, any> };

  waterCraftData: any;
  waterCraftTypes: any[];
  mapper: Record<string, boolean>;
}

export default Vue.extend({
  inheritAttrs: false,
  name: "HO215Fields",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [Boolean, String, Number],
      required: false,
      default: false
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    endorsement: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data(): IData {
    return {
      HO215Data: {
        HO215Des1: {},
        HO215HP: {},
        HO215Des2: {},
        HO215MPH: {},
        HO215Len2: {},
        HO215Des3: {},
        HO215Ax: {},
        HO215Len3: {},
        m_fmNumWaterCraft: {},
        waterCraftData: {}
      },

      waterCraftData: {},
      waterCraftTypes: selectOptions.waterCraftTypes,
      mapper: {}
    };
  },
  mounted() {
    const dataObject = this.endorsement.values.reduce((acc: any, item: any) => {
      const key = item.ref;
      const value = {
        ref: item.ref,
        value: item.value
      };
      acc[key] = value;
      return acc;
    }, {});

    const valuesArray: any[] = Object.keys(this.HO215Data);
    if (this.endorsement && this.endorsement.values) {
      if (Object.keys(dataObject).length > 1) {
        this.HO215Data = dataObject;
      } else {
        for (let value of valuesArray) {
          const key: HO215Keys = value;
          const optionFound = this.endorsement.values.find(
            (option: { ref: string }) => option.ref === value
          );

          if (!optionFound) {
            this.endorsement.values.push({
              ref: value,
              value: ""
            });
            this.HO215Data[key] = {
              ref: value,
              value: ""
            };
          } else {
            this.HO215Data[key] = optionFound;
          }
        }
      }
    }
  },
  methods: {
    populateEndorsementValueOptions(value: { ref: string; value: any }) {
      for (const obj of this.endorsement.values) {
        if (obj.ref === value.ref) {
          if (obj.value && value.ref === "waterCraftData") {
            obj.value = {
              ...obj.value,
              ...value.value
            };
          } else {
            obj.value = value.value;
          }
        }
      }
    },
    getEndorsementOptionByRef(ref: string) {
      return this.endorsement.values.findIndex(
        (option: { ref: string; value: any }) => option.ref === ref
      );
    },
    inputValueChanged($event: any, ref: any, key?: any): void {
      if (ref === "m_fmNumWaterCraft") {
        $event = Number($event);
        if (!$event) {
          this.populateEndorsementValueOptions({
            value: {},
            ref: "waterCraftData"
          });
          this.HO215Data[ref as HO215Keys].value = $event;
          this.HO215Data.waterCraftData = {};
        }
      }

      set(this, ref, $event);
      let value = {
        value: $event,
        ref: ref
      };

      if (ref === "waterCraftData") {
        const itemExists = this.mapper[ref];
        const optionFound = this.endorsement.values.find(
          (option: { ref: string }) => option.ref === ref
        );
        const valueToUpdate = {
          value: {
            [key]: $event
          },
          ref
        };
        if (itemExists || optionFound) {
          this.populateEndorsementValueOptions(valueToUpdate);
        } else {
          this.endorsement.values.push(valueToUpdate);
          this.mapper[ref] = true;
        }
      } else {
        this.HO215Data[ref as HO215Keys].value = $event;
        this.populateEndorsementValueOptions(value);
      }
      this.$emit("change", value);
    },
    getSelectedType(key: any) {
      return this.HO215Data.waterCraftData.value[key];
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    validationResult(): {
      isValid: boolean;
      error: string;
    } {
      return checkVuelidateValidation(this.validations as any, this.value);
    }
  }
});
