
import Vue from "vue";
import * as selectOptions from "@/helpers/selectOptions";
export default Vue.extend({
  name: "AddressViewer",
  props: {
    address: {
      type: Object,
      required: true,
      default: () => ({})
    },
    addressLabel: {
      type: String,
      required: true,
      default: "Physical Address"
    },
    hideTopBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      valueClass: "mt-1 text-sm text-gray-900 uppercase"
    };
  },
  methods: {
    formatStreetType(streetType: string) {
      return selectOptions.streetTypes.find(
        (type: any) => type.value === streetType
      )?.label;
    }
  },
  computed: {
    canDisplayAddress() {
      if (Object.keys(this.address).length == 0) return false;
      return Object.values(this.address).some(
        (value: any) => value != null && value != "" && value.length > 0
      );
    }
  }
});
